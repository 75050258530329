<template>
  <div>
    <q-dialog v-model="prompt" persistent>
      <q-card style="min-width: 350px">
        <q-toolbar class="bg-orange-7 text-white shadow-2">
          <q-toolbar-title>일상/열화 선택</q-toolbar-title>
        </q-toolbar>

        <q-list class="listArea" bordered separator>
          <q-item tag="label" clickable v-ripple>
            <q-item-section avatar top>
              <q-radio v-model="pick" val="daily" active-class="bg-teal-1 text-grey-8" />
            </q-item-section>
            <!-- <q-item-section avatar>
              <q-icon name="build" class="text-orange-9"/>
            </q-item-section> -->
            <q-item-section>
              <q-item-label class="text-orange-9 labelArea"><b>1.&nbsp;&nbsp;&nbsp;일상점검</b></q-item-label>
            </q-item-section>
          </q-item>
          <q-item tag="label" clickable v-ripple>
            <q-item-section avatar top>
              <q-radio v-model="pick" val="deterior" active-class="bg-teal-1 text-grey-8" />
            </q-item-section>
            <!-- <q-item-section avatar>
              <q-icon name="build" class="text-orange-9"/>
            </q-item-section> -->
            <q-item-section>
              <q-item-label class="text-orange-9 labelArea"><b>2.&nbsp;&nbsp;&nbsp;열화관리점검</b></q-item-label>
            </q-item-section>
          </q-item>
        </q-list>

        <q-card-actions align="right" class="text-primary">
          <q-btn 
            rounded 
            size="sm" 
            icon="login" 
            style="widh:100%" 
            type="submit"
            label="이동" 
            @click="select" 
          />
          <q-btn 
            rounded 
            size="sm" 
            icon="cancel" 
            style="widh:100%" 
            label="취소" 
            @click="cancel" 
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
export default {
  name: 'equipment-qr-select',
  data() {
    return {
      editable: true,
      prompt: true,
      equipmentCd: '',
      pick: null, 
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      if (this.$route.query.equipmentCd) {
        this.equipmentCd = this.$route.query.equipmentCd
      }
    },
    select() {
      if (!this.pick) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '점검할 항목을 선택하세요.',
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      this.prompt = false;

      if (this.pick === 'daily') {
        this.$router.push("/sop/mim/inspection/equipmentDailyInspection?equipmentCd=" + this.equipmentCd);
      } else if (this.pick === 'deterior') {
        this.$router.push("/sop/mim/inspection/equipmentDeteriorInspection?equipmentCd=" + this.equipmentCd);
      }
    },
    cancel() {
      this.prompt = false;

      this.$router.push("/sys/appr/qrreader");
    },
  }
};
</script>
<style>
.labelArea {
  font-size: 17px
}
</style>